.container-drag {
  padding: 8px;
  width: 100%;
  min-height: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 8px; 
}

.content-drag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
  margin-bottom: 8px;
}

.add-button {
  margin-top: 1rem !important;
}

.title {
  text-align: left !important;
}

.container-images{
  display:grid!important;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 8px; 
}

#file-previews, #file-previews2{
  display:grid!important;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 8px; 
}