.remove-enter-button{
  background-color: #888;
  width:120px;
  max-width:120px!important;
  height:35px;
  margin-left:30px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:8px;
  color:#fff;
  font-size:16px;
  cursor:pointer;
  border:none;
}