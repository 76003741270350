.container-drag {
  padding: 8px;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4px; 
}

.content-drag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 4px); 
  margin-bottom: 8px;
}

.add-button {
  margin-top: 1rem !important;
}

.title {
  text-align: left !important;
}